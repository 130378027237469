import React, { useEffect, useState } from "react";
import Spinner from "../Spinner/Spinner";
import ApiService from "@/utils/ApiServices";
import ApiUrl from "@/utils/ApiUrl";
import CourseFilterOneToggle from "../Category/Filter/CourseFilterOneToggle";
import Cookies from "js-cookie";

const CourseSchool = ({ serverPrograms }) => {
  const [loading, setLoading] = useState(true);
  const [allCourses, setAllCourses] = useState([]);
  let parsedUserInfo = null;
  const userInfo = Cookies.get('userInfo') || '{}';;
   parsedUserInfo = JSON.parse(userInfo);
   const getPaymentStatus = async () => {
    try {
      const response = await ApiService.getApiService(
      ApiUrl.GET_PAYMENT_STATUS + `?user_id=${parsedUserInfo?.user?.id}`);
      const status = response?.results?.courseIds || [];
      console.log("Fetched payment status:", status);
      await getAllPrograms(status);
    } catch (error) {
      console.error("Error fetching payment status:", error.message);
      await getAllPrograms([]);
    }
  };

  const getAllPrograms = async (status) => {
    try {
      setLoading(true);
      const fetchedCourses = serverPrograms || [];
      const updatedCoursesList = fetchedCourses.map(course => ({
        ...course,
        isPayment: status.includes(course._id)
      }));
      setAllCourses(updatedCoursesList);
    } catch (error) {
      console.error("Error fetching all programs:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentStatus();
  }, []);

  return (
    <>
      <div className="rbt-banner-area rbt-banner-2 header-transperent-spacer">
        <div className="wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner-content text-center">
                  <div className="inner">
                    {/* <div className="rbt-new-badge rbt-new-badge-one mb--30">
                      <span className="rbt-new-badge-icon">🏆</span> Your Premier Destination for Mindfulness, Meditation
                      & Manifestation
                    </div> */}
                    <h1 className="title">
                    Discover the path to{" "}
                      <span className="theme-gradient">purpose, fulfillment, </span>{" "}
                      and{" "}
                      <span className="theme-gradient">inner harmony.</span>{" "}
                      {/* Programs */}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="d-flex justify-content-center">
                <div>
                <Spinner/>
                </div>
              </div>
            ) : (
              allCourses?.length > 0 ? (
                <CourseFilterOneToggle course={allCourses} />
              ) : (
                <p>No courses available.</p>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseSchool;
