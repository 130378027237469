import PageHead from "../Head";
import { Provider } from "react-redux";
import Store from "@/redux/store";
import Context from "@/context/Context";
import CourseSchool from "@/components/02-course-school/CourseSchool";
const CourseSchoolPage = ({ allPrograms }) => {
  return (
    <>
      <PageHead title="Course School - Online Courses & Education NEXTJS14 Template" />
      <Provider store={Store}>
        <Context>
          <CourseSchool serverPrograms={allPrograms} />
        </Context>
      </Provider>
    </>
  );
};

export default CourseSchoolPage;

